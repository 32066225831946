import $ from 'jquery';
import customSelect from 'custom-select';

const collectCheckedValues = (selector) =>
  $(selector + ' input:checked').map((_, el) => $(el).val()).get();

//filter posts
const filterPosts = (page, requestedPostType = null, firstLoad = null) => {
    const $postsGrid = $('.posts-grid');
    if ($postsGrid.hasClass('loading')) return;
    $postsGrid.addClass('loading');

    let resources = [],
      cats = [],
      order = $('#order').val(),
      featuredText = 'Featured';

    resources = collectCheckedValues('.resources');
    cats = collectCheckedValues('.categories');

    //load selected post type on first page load or news
    if (!resources.length && firstLoad) {
      const urlParams = new URLSearchParams(window.location.search);
      const category = urlParams.get('category');

      if ( category ) {
        const $input = $(`.resources input[value="${category}"]`);
        $input.prop('checked', true);
        $(`.resources-dropdown input[value="${category}"]`).prop('checked', true);
        featuredText = $input.next('label').html();
        resources.push(category);
      }
    } else {
      if ( resources.length ) featuredText = $(`input[value="${resources[0]}"]`).next('label').html();
    }

    //check for title display and post type name
    let $title = $('.right-block .title');
    if( order === 'asc' && !resources.length && !cats.length ) {
      $title.hide();
    } else {
      $title.show().html(featuredText);
      //check for categories and add them to the title
      if( cats.length ) {
        resources.length ? $title.append(' in ') : $title.html('');
        $title
          .append(cats.length > 1 ? 'Categories:' : 'Category:')
          .append(cats.map(cat => $(`input[value="${cat}"]`).next('label').html()).join(', '));
      }
    }

    //create fetch request with the data
    fetch(`/wp-json/fwp/posts?categories=${cats.join(',')}&types=${resources.join(',')}&order=${order}&page=${page}`)
      .then(response => response.text())
      .then(data => {
        $postsGrid.html(data).removeClass('loading');
      })
      .catch(error => {
        console.error('Error:', error);
        $postsGrid.removeClass('loading');
      });
};

$(document).ready(function () {
  libraryWidget();
  filterPosts(1, null, 1);
});

function libraryWidget() {
  //enable custom select
  customSelect(document.getElementById('order'));

  //toggle checkboxes blocks
  $(document).on('click', '.block-container .title', function () {
    $(this).toggleClass('active').next('form').slideToggle();
  });

  //toggle select dropdown
  $(document).on('click', '.select-container span', function () {
    $(this).toggleClass('active').next('.dropdown').slideToggle(50);
  });

  //post type select
  $(document).on('click', '#library-widget .resources input', function () {
    let $this = $(this);
    //uncheck all .resources inputs except the clicked one
    $('.resources input, .resources-dropdown input').not($this).prop('checked', false);
    filterPosts(1, $this.val());
    //checked input with the same value on top-block
    $(`.resources-dropdown input[value="${$this.val()}"]`).prop('checked', $this.prop('checked'));
  });

  //top filters post type select
  $(document).on('click', '.top-block .resources-dropdown input', function () {
    $('#' + $(this).val()).click();
  });

  //categories select
  $(document).on('change', '#library-widget .categories input', function () {
    filterPosts(1);
    $(`.top-block .categories-dropdown input[value="${$(this).val()}"]`).prop('checked', $(this).prop('checked'));
  });

  //top filters post type select
  $(document).on('click', '.top-block .categories-dropdown input', function () {
    $(`.categories input[value="${$(this).val()}"]`).click();
  });


  //trigger order select change
  const $orderSelect = $('#order');
  if ($orderSelect.length) {
    $orderSelect.on('change', () => filterPosts(1));
  }

  // click on pagination
  $(document).on('click', '.grid-pagination a', function (e) {
    e.preventDefault();
    const page = $(this).attr('href').split('page/')[1].split('/')[0];
    filterPosts(page);
  });
}
